var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"centered":"","no-close-on-backdrop":"","title":_vm.title},on:{"hidden":_vm.handleHidden},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isLoading},on:{"click":_vm.save}},[_vm._v("Guardar")])]},proxy:true}]),model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('b-form-group',{attrs:{"label":"Fecha"}},[_c('b-form-datepicker',{attrs:{"date-format-options":{
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
				}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('b-form-group',{attrs:{"label":"Buscar Madrilla"}},[_c('v-select',{attrs:{"options":_vm.madrillaOpts,"selectable":_vm.filterOpts,"label":"query"},on:{"option:selected":_vm.handleSelected},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
				var alias = ref.alias;
				var plate = ref.plate;
return [(alias)?_c('span',{domProps:{"textContent":_vm._s((alias + " / (" + plate + ")"))}}):_c('span',{domProps:{"textContent":_vm._s(("(" + plate + ")"))}})]}},{key:"option",fn:function(ref){
				var alias = ref.alias;
				var plate = ref.plate;
return [(alias)?_c('span',{domProps:{"textContent":_vm._s((alias + " / (" + plate + ")"))}}):_c('span',{domProps:{"textContent":_vm._s(("(" + plate + ")"))}})]}}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "+_vm._s(_vm.search)+" "),_c('hr'),_c('h6',{staticClass:"mb-2"},[_vm._v("Madrillas seleccionadas: "+_vm._s(_vm.madrillas.length))]),_c('div',{staticClass:"bg-light border-secondary rounded",staticStyle:{"overflow-x":"hidden","overflow-y":"auto","max-height":"12rem"}},[_c('b-row',{staticClass:"py-1 gap-y-2"},[_vm._l((_vm.madrillas),function(madrilla,idx){return _c('b-col',{key:madrilla.id,staticClass:"text-center",attrs:{"cols":"6"}},[_c('b-badge',{attrs:{"variant":"primary"}},[(madrilla.alias)?_c('span',[_vm._v(_vm._s(madrilla.alias)+" ("+_vm._s(madrilla.plate)+")")]):_c('span',[_vm._v("("+_vm._s(madrilla.plate)+")")]),_c('b-button',{staticClass:"btn-icon ml-05",staticStyle:{"padding":"0.2rem"},attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.removeSelected(idx)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon","size":"16"}})],1)],1)],1)}),(_vm.madrillas.length == 0)?_c('b-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',[_vm._v("Aun no hay madrillas seleccionadas")])]):_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }