var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-add-postura-eggs",attrs:{"title":_vm.title,"no-close-on-backdrop":"","cancel-title":"Cancelar","scrollable":"","centered":_vm.is_eggs_found,"size":"md"},on:{"hidden":function($event){return _vm.$emit('hidden')}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v("Cancelar")]),(!_vm.loading)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v("Guardar")]):_c('b-button',{attrs:{"variant":"primary","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Cargando... ")],1)]}}])},[_c('div',[_c('ValidationObserver',{ref:"form"},_vm._l((_vm.arrayPosturas),function(postura,index){return _c('b-col',{key:(index + "-postura"),staticClass:"position-relative pr-2 pl-2"},[_c('b-row',[_c('b-form-group',{staticClass:"w-full",attrs:{"label-for":'madrilla-' + index,"label":"Madrilla:"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:{ 'border-danger rounded': errors[0] },attrs:{"value":_vm.formattedMadrilla(postura.madrilla),"id":'madrilla-' + index,"disabled":_vm.is_eggs_air}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),(!_vm.same_padrillo && !_vm.withPad)?_c('b-row',[_c('b-form-group',{staticClass:"w-full",attrs:{"label-for":'padrillo-' + index,"label":"Elige el padrillo:","description":""}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors[0] },attrs:{"id":'padrillo' + index,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.arrayPadrillos,"clearable":false,"label":"alias","reduce":function (option) { return option; },"filter":_vm.fuseSearch},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var alias = ref.alias;
var plate = ref.plate;
return [(alias)?_c('span',{domProps:{"textContent":_vm._s((alias + " / (" + plate + ")"))}}):_c('span',{domProps:{"textContent":_vm._s(("(" + plate + ")"))}})]}},{key:"option",fn:function(opt){return [(opt.selected)?_c('b',[_vm._v(" ** "+_vm._s(opt.alias || "---")+" / ("+_vm._s(opt.plate || "---")+") "),(opt.counter)?_c('small',[_vm._v("NP: "+_vm._s(opt.counter))]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(opt.alias || "---")+" / ("+_vm._s(opt.plate || "---")+") "),(!opt.enabled)?_c('span',[_vm._v("- Deshabilitado")]):_vm._e()])]}}],null,true),model:{value:(postura.padrillo),callback:function ($$v) {_vm.$set(postura, "padrillo", $$v)},expression:"postura.padrillo"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('small',[_vm._v("Padrillos con (**): Fueron marcados como excedente de inseminacion")])],1)],1):_vm._e(),_c('b-row',[_c('b-form-group',{staticClass:"w-full",attrs:{"id":"date","label":"Fecha","label-for":"date"}},[_c('b-form-datepicker',{attrs:{"id":"date","date-format-options":{
								year: 'numeric',
								month: 'numeric',
								day: 'numeric',
							}},model:{value:(postura.postura_date),callback:function ($$v) {_vm.$set(postura, "postura_date", $$v)},expression:"postura.postura_date"}})],1)],1),_c('b-row',[_c('b-form-group',{staticClass:"w-full",attrs:{"id":"observation","label":"Comentario","label-for":"observation"}},[_c('b-form-textarea',{staticStyle:{"resize":"none"},attrs:{"id":"observation","rows":"3"},model:{value:(postura.observation),callback:function ($$v) {_vm.$set(postura, "observation", $$v)},expression:"postura.observation"}})],1)],1)],1)}),1),(_vm.is_eggs_found)?_c('div',{staticClass:"ml-2 mr-2"},[_c('p',{staticClass:"alert alert-warning p-1 w-100"},[_vm._v("Esta postura sera creada en estado pendiente.")])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }