import axios from "@/axios";

const url = "/api/egg";

class EggService {
	async registerEggs({ register_eggs_date, posturas, created_by, register_eggs_comment, total_register_eggs }) {
		try {
			await axios.post(`api/eggs/register-eggs`, {
				register_eggs_date,
				posturas,
				created_by,
				register_eggs_comment,
				total_register_eggs,
			});
		} catch (error) {
			console.log(error);
			return error;
		}
	}
	async getEggsByMonth({ dateIn, dateFn }) {
		try {
			const res = await axios.get(url + "/by-month", { params: { dateIn, dateFn } });
			return res;
		} catch (err) {
			console.error(err);
		}
	}
	async eggsAvailableWithMadrillas({ start_date, end_date }) {
		try {
			const { data } = await axios.get(url + "/eggs-available-with-madrillas", {
				params: { dateIn: start_date, dateFn: end_date },
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}
	async posturasWithEggsByMadrillas({ start_date, end_date }) {
		try {
			const { data } = await axios.get(url + "/posturas-with-eggs-by-madrillas", { params: { dateIn: start_date, dateFn: end_date } });
			return data;
		} catch (err) {
			console.error(err);
		}
	}
	// DETALLE POR FECHA DEL DIA DE LA CANTIDAD DE HUEVOS DEL MES SELECCIONADO
	async getEggsByMonthDetail({ dateIn, dateFn }) {
		try {
			const { data } = await axios.get(url + "/by-month-detail", { params: { dateIn, dateFn } });
			return data;
		} catch (err) {
			console.error(err);
		}
	}
	async getEggsByMonthDetailByUser({ dateIn, dateFn }) {
		try {
			const { data } = await axios.get(url + "/by-month-detail-by-user", { params: { dateIn, dateFn } });
			return data;
		} catch (err) {
			console.error(err);
		}
	}
	async getEggsByIdEggs({ idEggsIn, dateIn, dateFn, dateOnly, dayOnly }) {
		try {
			const { data } = await axios.post(url + "/by-id-eggs", { idEggsIn, dateIn, dateFn, dateOnly, dayOnly });
			return data;
		} catch (err) {
			console.error(err);
		}
	}
	async getEggsByPostura({ postura_id }) {
		try {
			const { data } = await axios.get(`${url}/get-eggs-by-postura/${postura_id}`);
			return data;
		} catch (err) {
			console.error(err);
		}
	}
	async updateEggsByPostura({ eggs_id, egg_postura_id, quantity, quantity_anterior, postura_id, userIn }) {
		try {
			const { data } = await axios.post(url + "/update-eggs-by-postura", {
				eggs_id,
				egg_postura_id,
				quantity,
				quantity_anterior,
				postura_id,
				userIn,
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}
	async getTrackingEggsByPostura({ postura_id }) {
		try {
			const { data } = await axios.post(url + "/get-tracking-eggs-by-postura", { postura_id });
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async insertNotification({ quantity, madrilla_id }) {
		try {
			const { data } = await axios.post(`/api/eggs/insert-eggs-in-the-air`, {
				quantity,
				madrilla_id,
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async counterMadrillasRecomended() {
		try {
			const { data } = await axios.get(`/api/eggs/get-count-madrillas-recomendadas`);
			return data;
		} catch (err) {
			console.error(err);
		}
	}
}
export default new EggService();
