<template>
	<b-modal centered no-close-on-backdrop :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-form-group label="Fecha">
				<b-form-datepicker
					v-model="date"
					:date-format-options="{
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
					}"
				/>
			</b-form-group>
			<b-form-group label="Buscar Madrilla">
				<v-select
					v-model="search"
					:options="madrillaOpts"
					:selectable="filterOpts"
					@option:selected="handleSelected"
					label="query"
				>
					<template #selected-option="{ alias, plate }">
						<span v-if="alias" v-text="`${alias} / (${plate})`"></span>
						<span v-else v-text="`(${plate})`"></span>
					</template>
					<template v-slot:option="{ alias, plate }">
						<span v-if="alias" v-text="`${alias} / (${plate})`"></span>
						<span v-else v-text="`(${plate})`"></span>
					</template>
				</v-select>
			</b-form-group>
			{{ search }}
			<hr />
			<h6 class="mb-2">Madrillas seleccionadas: {{ madrillas.length }}</h6>
			<div
				class="bg-light border-secondary rounded"
				style="overflow-x: hidden; overflow-y: auto; max-height: 12rem"
			>
				<b-row class="py-1 gap-y-2">
					<b-col cols="6" class="text-center" v-for="(madrilla, idx) in madrillas" :key="madrilla.id">
						<b-badge variant="primary">
							<span v-if="madrilla.alias">{{ madrilla.alias }} ({{ madrilla.plate }})</span>
							<span v-else>({{ madrilla.plate }})</span>
							<b-button
								class="btn-icon ml-05"
								variant="danger"
								size="sm"
								style="padding: 0.2rem"
								@click="removeSelected(idx)"
							>
								<feather-icon icon="TrashIcon" size="16" />
							</b-button>
						</b-badge>
					</b-col>
					<b-col cols="12" class="text-center" v-if="madrillas.length == 0">
						<span>Aun no hay madrillas seleccionadas</span>
					</b-col>
				</b-row>
			</div>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="primary" :disabled="isLoading" @click="save">Guardar</b-button>
		</template>
	</b-modal>
</template>

<script>
import moment from "moment"
import { bus } from "@/main";
import axios from "@/axios"
import posturasService from "@/services/posturas.service"

export default {
	props: {
		show: Boolean,
		info: Object,
		fromGrid: {
			type: Boolean,
			default: false
		},
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: "",
		date: moment().format("YYYY-MM-DD"),
		search: null,
		madrillaOpts: [],
		madrillas: [],
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			;(this.isLoading = false), (this.date = moment().format("YYYY-MM-DD")), (this.madrillas = [])
			this.$emit("closing")
		},
		async getOpts() {
			this.isLoading = true
			const { data } = await axios.get("api/postura/get-madrillas-being-used")
			this.madrillaOpts = data.map((opt) => ({ ...opt, query: `${opt.alias}-${opt.plate}` }))
			this.isLoading = false
		},
		handleSelected(opt) {
			this.madrillas.push(opt)
			this.search = null
		},
		removeSelected(idx) {
			this.madrillas.splice(idx, 1)
		},
		filterOpts(opt) {
			const selectedIds = this.madrillas.map((m) => m.id)
			if (selectedIds.includes(opt.id)) return false
			return true
		},
		async save() {
			if (this.madrillas.length == 0) {
				this.showToast(
					"danger",
					"top-right",
					"Posturas",
					"AlertCircleIcon",
					"Tienes que haber al menos una madrilla seleccionada"
				)
				return
			}
			this.isLoading = true
			const payload = this.madrillas.map((m) => ({
				madrilla: m,
				padrillo: this.info,
				observation: null,
				postura_date: this.date,
			}))
			const { message } = await posturasService.savePostura(payload)
			this.showSuccessToast(message)

			this.isLoading = false
			if(this.fromGrid){
				bus.$emit("refreshCounters")
			}
			this.handleHidden()
			this.$emit("refresh")
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				let specimen = ""
				if (this.info.alias) specimen = `${this.info.alias} (${this.info.plate})`
				else specimen = `(${this.info.plate})`
				this.title = `AGREGAR MADRILLAS / ${specimen}`
				this.getOpts()
			}
		},
	},
}
</script>
