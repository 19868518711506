<template>
	<div>
		<b-modal
			ref="madrillas-with-insemination-pending-modal"
			centered
			@hidden="$emit('close')"
			v-model="isActive"
			:title="'Madrillas por inseminar'"
			:size="widthScreen >= 1200 ? 'xmd' : 'xlg'"
		>
			<div class="table-responsive mb-0">
				<div class="d-flex">
					<b-card class="text-center rounded-lg">
						<div class="d-flex flex-column gap-y-2">
							<SpecimenPlate :specimen="info" />
							<!-- <ChangeSpecimenEggEater
								dashboard
								:info="info.specimen"
								:type="'padrillo'"
								:disabled="true"
							/> -->
						</div>
					</b-card>
				</div>
				<b-table-simple sticky-header="50vh" class="table mb-0">
					<b-thead>
						<b-tr class="">
							<b-th>
								<div class="d-flex align-items-center justify-content-center cursor-pointer">
									<p class="mb-0 mr-1">Madrillas</p>
								</div>
							</b-th>
							<b-th>
								<div class="d-flex align-items-center justify-content-center cursor-pointer">
									<p class="mb-0 mr-1">Tipo</p>
								</div>
							</b-th>
							<b-th>
								<div class="d-flex align-items-center justify-content-center cursor-pointer">
									<p class="mb-0 mr-1 text-center">Última inseminación</p>
								</div>
							</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr class="text-center" v-for="(item, index) in madrillas" :key="index">
							<b-td class="">
								<div class="relative">
									<div class="d-flex align-items-center justify-content-end">
										<font-awesome-icon
											v-if="item.eggs_madrilla_id"
											icon="fa-solid fa-egg"
											size="xl"
											class="text-warning mr-3"
											v-b-tooltip.hover.top="'Huevo registrado'"
										/>
									</div>
									<SpecimenPlate
										:specimen="{
											id: item.madrilla.id,
											plate: item.madrilla.plate,
											alias: item.madrilla.alias,
											thumb: item.madrilla.thumb,
											image: item.madrilla.url,
										}"
									/>
								</div>
							</b-td>
							<b-td class="">
								{{ item.madrilla.method }}
							</b-td>
							<b-td class="" v-if="item.madrilla.last_insemination">
								<div class="d-flex flex-column align-items-center justify-content-center">
									<span>
										{{ item.madrilla.last_insemination | myGlobalDayShort }}
									</span>
									<span class="text-primary font-weight-bold" v-if="!item.madrilla.is_pending">
										{{ "Inseminado" }}
									</span>
								</div>
							</b-td>
							<b-td v-else>
								{{ "Sin inseminación" }}
							</b-td>
						</b-tr>
						<b-tr v-if="madrillas.length == 0">
							<b-td colspan="7" class="text-center">
								<p class="mb-0 font-weight-bold">Sin madrillas encontradas</p>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>

			<template #modal-footer>
				<div class="d-flex justify-content-end w-100">
					<b-button variant="danger" @click="$emit('close')">Cerrar</b-button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import modalMixin from "@/mixins/modal"
import { isAdmin } from "@/store/auth/getters"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import FertilidadService from "@/views/amg/encaste/posturas/insemination/fertilidad.service.js"

export default {
	components: { SpecimenPlate },
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			madrillas: [],
		}
	},
	async created() {
		await this.getMadrillas()
	},
	async mounted() {
		this.toggleModal("madrillas-with-insemination-pending-modal")
	},
	computed: {
		widthScreen() {
			return this.$store.state.app.windowWidth
		},
	},
	methods: {
		async getMadrillas() {
			this.isPreloading()

			try {
				const { data } = await FertilidadService.getAvailableMadrillasForPadrillo({
					padrillo_id: this.info.id,
				})
				this.madrillas = data
			} catch (error) {
				console.log("error: ", error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
}
</script>
