import axios from "@/axios";

const url = "/api/encaste/recommended";

class RecomendedService {
	async get({ perpage, page }) {
		try {
			const { data } = await axios.get(`${url}/get-madrillas-recommended`, {
				params: {
					perpage,
					page,
				},
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async delete({ recomended_id }) {
		try {
			const { data } = await axios.delete(`${url}/delete-madrilla-recommended/${recomended_id}`, {});
			return data;
		} catch (err) {
			console.error(err);
			return err.response.data;
		}
	}

	async getMadrillaRecommendedWithPostura({ page, perpage }) {
		try {
			const { data } = await axios.get(`${url}/get-madrilla-recommended-with-postura`, {
				params: {
					page,
					perpage,
				},
			});
			return data;
		} catch (err) {
			console.error(err);
			return err.response.data;
		}
	}
}
export default new RecomendedService();
