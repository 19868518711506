<template>
	<div>
		<b-modal
			ref="padrillos-posture-modal"
			:title="'Padrillos en Postura | Clasificados para Inseminación Hoy'"
			centered
			v-model="isActive"
			@hidden="handleHidden"
			size="lg"
			hide-footer
		>
			<b-overlay :show="isLoading">
				<b-row class="mb-1">
					<b-col class="d-flex align-items-center">
						<b-tabs v-model="indexTab">
							<b-tab :disabled="false">
								<template #title>Programados</template>
							</b-tab>
							<b-tab :disabled="false">
								<template #title>No programados</template>
							</b-tab>
						</b-tabs>
					</b-col>
				</b-row>

				<b-card v-if="indexTab === 0">
					<b-row>
						<b-col class="d-flex align-items-center">
							<b-tabs v-model="indexSubTab">
								<b-tab :disabled="false">
									<template #title>Inseminados</template>
								</b-tab>
								<b-tab :disabled="false">
									<template #title>Por inseminar</template>
								</b-tab>
							</b-tabs>
						</b-col>
					</b-row>
				</b-card>
				<b-table-simple responsive sticky-header="60vh" no-border-collapse>
					<b-thead class="sticky-header">
						<b-th class="text-center">Padrillo</b-th>
						<b-th class="text-center">Nº Madrillas</b-th>
					</b-thead>
					<b-tbody>
						<template v-for="(data, index) in specimens">
							<b-tr :key="index">
								<b-td class="text-center">
									<div
										style="
											display: flex;
											gap: 1rem;
											align-items: flex-start;
											justify-content: center;
										"
									>
										<v-icon
											v-if="data.inseminateds > 0 && indexSubTab === 1"
											name="gi-eyedropper"
											scale="1.2"
											class="text-primary"
											v-b-tooltip.hover.bottom="'Ya tuvo una inseminación'"
										/>

										<SpecimenPlate :specimen="data" />
									</div>
								</b-td>

								<b-td class="text-center">
									<h4>
										<b-badge
											variant="light-primary"
											class="cursor-pointer"
											@click="showModalMadrillas(data)"
										>
											{{ data.posturas }}
										</b-badge>
									</h4>
								</b-td>
							</b-tr>
						</template>
						<b-tr v-if="specimens.length == 0">
							<b-td colspan="10" class="text-center" style="height: 80px">
								No hay registros para mostrar
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</b-overlay>

			<MadrillasWithInseminationPending
				v-if="showMadrillasModal"
				:show="showMadrillasModal"
				:info="infoPadrillo"
				@close="showMadrillasModal = false"
			/>
		</b-modal>
	</div>
</template>

<script>
import modalMixin from "@/mixins/modal"
import EncasteService from "@/services/encaste.service"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import MadrillasWithInseminationPending from "@/views/amg/encaste/posturas/modals/MadrillasWithInseminationPending.vue"

export default {
	components: {
		SpecimenPlate,
		MadrillasWithInseminationPending,
	},
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			indexTab: 0,
			indexSubTab: 0,
			specimens: [],
			infoPadrillo: null,
			showMadrillasModal: false,
		}
	},
	async created() {
		await this.getSpecimens()
	},
	computed: {},
	methods: {
		showModalMadrillas(padrillo) {
			this.infoPadrillo = padrillo
			this.showMadrillasModal = true
		},
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
		getTooltipContent(madrillas) {
			return `<ul style="margin: 0; padding-left: 1rem">${madrillas
				.map(
					(madrilla) =>
						`<li>${madrilla.alias ? `${madrilla.alias} / (${madrilla.plate})` : `(${madrilla.plate})`}</li>`
				)
				.join("")}</ul>`
		},
		async getSpecimens() {
			this.isPreloading()
			try {
				const { data } = await EncasteService.getSpecimensAvailables({
					tab_id: this.indexTab === 0 ? 1 : 2,
					insemination: this.indexSubTab === 0 ? 1 : 2,
				})
				this.specimens = data
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
	watch: {
		indexTab: async function () {
			await this.getSpecimens()
		},
		indexSubTab: async function () {
			await this.getSpecimens()
		},
	},
	async mounted() {
		this.toggleModal("padrillos-posture-modal")
	},
}
</script>

<style scoped>
.card-body {
	padding: 1rem !important;
}
</style>
