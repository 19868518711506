<template>
	<div class="font-weight-normal text-nowrap">
		<span v-if="computeDate.year">
			{{ computeDate.yearText }}
		</span>
		<span v-if="computeDate.months">
			{{ computeDate.monthsText }}
		</span>
		<span v-if="computeDate.days && !grid_specimen">
			{{ computeDate.daysText }}
		</span>
	</div>
</template>

<script>
import moment from "moment";

export default {
	name: "SpecimenAge",
	props: {
		age: {
			type: [Date, String],
		},
		grid_specimen: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		//11 meses 23 dias
		computeDate() {
			let dateCurrent = moment(new Date());
			let dateAge = moment(this.age);

			let year =  dateCurrent.diff(dateAge, "years");
			dateAge.add(year, 'years');
			
			let months = dateCurrent.diff(dateAge, "months");
			dateAge.add(months, 'months');

			let days =  dateCurrent.diff(dateAge, "days");

			return {
				year,
				yearText: [0, 1].includes(year) ? `${year} año` : `${year} años`,
				months,
				monthsText: [0, 1].includes(months) ? `${months} mes` : `${months} meses`,
				days,
				daysText: [0, 1].includes(days) ? `${days} dia` : `${days} dias`,
			};
		},
	},
};
</script>

<style></style>
