<template>
	<b-modal
		hide-footer
		centered
		no-close-on-backdrop
		size="lg"
		:title="title"
		v-model="isActive"
		@hidden="handleHidden"
	>
		<b-overlay :show="isLoading">
			<!-- <v-select
				class="mb-0"
				v-model="season"
				:options="seasons"
				label="season"
				:reduce="(option) => option.id"
				@input="getRows()"
				:clearable="false"
				transition=""
				placeholder="Seleccione un año"
				style="max-width: 14rem"
			/>
			<hr /> -->
			<b-table-simple class="text-center" sticky-header="60vh">
				<b-thead>
					<b-tr>
						<b-th>Enviado por</b-th>
						<b-th>Inicio</b-th>
						<b-th>Fin</b-th>
						<b-th>Duracion</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="(row, idx) in rows" :key="idx">
						<b-td>{{ row.created_by }}</b-td>
						<b-td>{{ row.start_date | myGlobalDayShort }}</b-td>
						<b-td>
							<span v-if="row.end_date">{{ row.end_date | myGlobalDayShort }}</span>
							<span v-else>EN CURSO</span>
						</b-td>
						<b-td>
							<span>{{ row.days_in_rest }} dia(s)</span>
						</b-td>
					</b-tr>
					<b-tr v-if="rows.length == 0">
						<b-td colspan="4">No hay datos para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
	</b-modal>
</template>

<script>
import specimenService from "@/services/specimens.service"

export default {
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: "",
		//
		season: null,
		rows: [],
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.padrillo = undefined
			this.$emit("closing")
		},
		async getRows() {
			this.isLoading = true
			const { data } = await specimenService.getRestingTracking({
				specimen_id: this.info.specimen.id,
			})
			this.rows = data
			this.isLoading = false
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.title = `Tracking de descansos / ${this.info.specimen.alias || this.info.specimen.plate}`
				this.season = this.seasons[0].id
				this.getRows()
			}
		},
	},
}
</script>
