import axios from "@/axios"

const url = "/api/encaste/"

class EncasteService {
	async getSpecimensAvailables({ tab_id, insemination }) {
		try {
			const { data } = await axios.get(`${url}specimens-availables`, { params: { tab_id, insemination } })
			return data
		} catch (error) {
			console.error(error)
		}
	}
	async getCounters() {
		try {
			const { data } = await axios.get(`${url}specimens-availables/counters`)
			return data
		} catch (error) {
			console.error(error)
		}
	}
}
export default new EncasteService()
